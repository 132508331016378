import type {
  QueryClient,
  QueryKey,
  QueryOptions,
} from '@tanstack/react-query';
import type { LoadOptions } from 'react-select-async-paginate';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import type { Option } from './SelectServerDriven';

type QueryParams = {
  limit?: number;
  offset?: number;
  searchString?: string;
  [key: string]: any; // Allow for additional query parameters
};

type QueryResult<T> = {
  data: T[];
  count: number;
};

type CreateLoadOptionsParams<T> = {
  createQueryKey: (params: QueryParams) => QueryKey;
  fetchItems: (params: QueryParams) => Promise<QueryResult<T>>;
  getOptionLabel?: (item: T) => string;
  getOptionValue?: (item: T) => string;
  pageSize?: number;
  queryClient: QueryClient;
  queryOptions?: Partial<QueryOptions>;
};

/**
 * Creates a loadOptions function for use with MultiSelectServerDriven (which is based on react-select-async-paginate).
 * This function handles pagination and search for a list of items.
 *
 * @template T - The type of the items being loaded.
 * @param {CreateLoadOptionsParams<T>} params - Configuration parameters for creating the loadOptions function.
 * @param {QueryClient} params.queryClient - The react-query client instance.
 * @param {function} params.createQueryKey - A function that creates the query key for fetching items.
 * @param {function} params.fetchItems - A function that fetches items from the API.
 * @param {function} params.getOptionLabel - A function that returns the label for an item.
 * @param {function} params.getOptionValue - A function that returns the value for an item.
 * @param {number} [params.pageSize] - Number of results per page.
 * @param {object} params.queryOptions - Additional query options.
 * @returns {LoadOptions<Option<T>, unknown, { page: number }>} A loadOptions function compatible with react-select-async-paginate.
 */
export function createLoadOptions<T>({
  queryClient,
  createQueryKey,
  fetchItems,
  getOptionLabel = ({ name }) => name,
  getOptionValue = ({ id }) => id,
  pageSize,
  queryOptions = {},
}: CreateLoadOptionsParams<T>): LoadOptions<
  Option<T>,
  unknown,
  { page: number }
> {
  return async (searchString, _, { page }) => {
    const queryParams: QueryParams = {
      offset: (page - 1) * (pageSize ?? PAGINATION_PAGE_SIZE_DEFAULT),
      searchString,
    };
    const queryKey = createQueryKey(queryParams);

    try {
      const { data, hasNextPage } = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => fetchItems(queryParams),
        ...queryOptions,
      });

      return {
        options: data.map(
          (item): Option<T> => ({
            value: getOptionValue(item),
            label: getOptionLabel(item),
            data: item, // Include the original item data
          }),
        ),
        hasMore:
          hasNextPage === undefined
            ? Boolean(data?.length === PAGINATION_PAGE_SIZE_DEFAULT) // Fallback while API doesn't return hasNextPage
            : hasNextPage,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error('Error loading items:', error);

      return {
        options: [],
        hasMore: false,
      };
    }
  };
}

type CreateGetItemDataParams<T> = {
  createQueryKey: (id: string) => unknown[];
  fetchItem: (id: string) => Promise<T>;
  getItemId?: (item: T) => string;
  queryClient: QueryClient;
  queryKeyBase: string[];
};

/**
 * Creates a function to retrieve item data based on React Query,
 * with a multi-level caching strategy and fallback to API fetch.
 *
 * @template T - The type of the item being retrieved.
 * @param {CreateGetItemDataParams<T>} params - Configuration parameters for creating the getItemData function.
 * @returns {function} A function that retrieves item data given an ID, following these steps:
 *   1. Checks the individual item cache.
 *   2. If not found, searches in the list cache.
 *   3. If still not found, fetches from the API.
 *   4. Returns undefined if an error occurs or if the ID is falsy.
 */
export function createGetItemData<T>({
  createQueryKey,
  fetchItem,
  getItemId = ({ id }) => id,
  queryClient,
  queryKeyBase,
}: CreateGetItemDataParams<T>) {
  return async (id: string): Promise<T | undefined> => {
    if (!id) {
      return undefined;
    }

    const queryKey = createQueryKey(id);

    try {
      // First, try to get the item from the cache.
      const cachedItem = queryClient.getQueryData<T>(queryKey);
      if (cachedItem) {
        return cachedItem;
      }

      // If not in cache, check if it's in the list cache.
      const queriesData = queryClient.getQueriesData(queryKeyBase);
      for (const [, queryData] of queriesData) {
        if (queryData && 'data' in queryData) {
          const item = (queryData.data as T[]).find(
            (item) => getItemId(item) === id,
          );
          if (item) {
            // // If found in list cache, update the individual item cache.
            // queryClient.setQueryData(queryKey, item);
            return item;
          }
        }
      }

      // If not found in any cache, fetch the item.
      const item = await queryClient.fetchQuery<T>({
        queryKey,
        queryFn: async () => fetchItem(id),
      });

      return item;
    } catch (error) {
      console.error('Error fetching item data:', error);
      return undefined;
    }
  };
}
