import Company from './Company';
import SignatureRoles from './SignatureRoles';
import PermissionGrant from './PermissionGrant';
import PushNotificationSettings from './PushNotificationSettings';
import ArrayUtils from '~/utils/arrayUtils';
import CSVValidator from './CSVValidator';
import UserFeatureFlags from './UserFeatureFlags';

export default class User {
  constructor(user) {
    this.id = user?.id ?? '';
    this.firstName = user?.first_name ?? user?.firstName ?? '';
    this.lastName = user?.last_name ?? user?.lastName ?? '';
    this.email = user?.email ?? '';
    this.position = user?.position ?? '';
    this.password = '';
    this.active = user?.is_active ?? user?.isActive ?? true;
    this.company = new Company({ id: user?.company_id ?? user?.companyId });
    this.signatureRoles = new SignatureRoles();
    this.featureFlags = new UserFeatureFlags();
    this.pushNotificationSettings = new PushNotificationSettings();

    this.organisationalGroups = user?.org_units ?? user?.orgUnits ?? [];
    this.organisationalGroupPaths =
      user?.org_unit_paths ?? user?.orgUnitPaths ?? [];
    this.userGroups = user?.user_groups ?? user?.userGroups ?? [];
    this.userGroupPaths = user?.user_group_paths ?? user?.userGroupPaths ?? [];

    this.permissionGrantsOn =
      (user?.permissions_on || user?.permissionsOn)?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            subjectType: PermissionGrant.SUBJECT_TYPE.USER.KEY,
            subjectId: this.id,
          }),
      ) ?? [];
    this.permissionGrantsFrom =
      (user?.permissions_from || user?.permissionsFrom)?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetType: PermissionGrant.ENTITY_TYPE.USER.KEY,
            targetId: this.id,
          }),
      ) ?? [];
  }

  getName() {
    return User.formatName(this.firstName, this.lastName);
  }

  getNameAndRole() {
    const name = this.getName();
    const position = this.position ? '(' + this.position + ')' : this.position;

    return (name + ' ' + position).trim();
  }

  // Get the sites of which the user is allowed to see delivery notes
  getDlnPermittedSites() {
    return this.permissionGrantsOn
      .filter(
        (permissionGrant) =>
          permissionGrant.entityType === PermissionGrant.ENTITY_TYPE.SITE.KEY &&
          permissionGrant.canReadDeliveryNotes(),
      )
      .map((permissionGrant) => permissionGrant.entityId);
  }

  // Get the cost centers of which the user is allowed to see delivery notes
  getDlnPermittedCostCenters() {
    return this.permissionGrantsOn
      .filter(
        (permissionGrant) =>
          permissionGrant.entityType ===
            PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY &&
          permissionGrant.canReadDeliveryNotes(),
      )
      .map((permissionGrant) => permissionGrant.entityId);
  }

  canReadDeliveryNotes() {
    return this.permissionGrantsOn.some((permissionGrant) =>
      permissionGrant.canReadDeliveryNotes(),
    );
  }

  getDefaultRoleNames() {
    return ArrayUtils.removeDuplicates(
      this.permissionGrantsOn.map((permissionGrant) =>
        permissionGrant.getDefaultRoleName(),
      ),
    )
      .sort()
      .join(', ');
  }

  isAssetCreator() {
    return this.id === User.ASSET_CREATOR_USER_ID;
  }

  static formatName(firstName, lastName) {
    if (!firstName && !lastName) {
      return '';
    }

    return (firstName ?? '') + ' ' + (lastName ?? '');
  }

  static getDifferentValues(oldUser, newUser) {
    const differentValues = [];

    if (oldUser?.id !== newUser?.id) {
      differentValues.push('ID');
    }

    if (oldUser?.firstName !== newUser?.firstName) {
      differentValues.push('Vorname');
    }

    if (oldUser?.lastName !== newUser?.lastName) {
      differentValues.push('Nachname');
    }

    if (oldUser?.email !== newUser?.email) {
      differentValues.push('E-Mail');
    }

    if (oldUser?.position !== newUser?.position) {
      differentValues.push('Position');
    }

    if (oldUser?.password !== newUser?.password) {
      differentValues.push('Passwort');
    }

    if (oldUser?.active !== newUser?.active) {
      differentValues.push('Aktiv');
    }

    if (oldUser?.company.id !== newUser?.company.id) {
      differentValues.push('Firma');
    }

    if (
      SignatureRoles.getDifferentValues(
        oldUser?.signatureRoles,
        newUser?.signatureRoles,
      ).length > 0
    ) {
      differentValues.push(
        SignatureRoles.getDifferentValues(
          oldUser?.signatureRoles,
          newUser?.signatureRoles,
        ).map((differentValue) => 'Signaturrollen (' + differentValue + ')'),
      );
    }

    if (
      UserFeatureFlags.getDifferentValues(
        oldUser?.featureFlags,
        newUser?.featureFlags,
      ).length > 0
    ) {
      differentValues.push(
        UserFeatureFlags.getDifferentValues(
          oldUser?.featureFlags,
          newUser?.featureFlags,
        ).map((differentValue) => 'Feature-Flags (' + differentValue + ')'),
      );
    }

    if (
      PushNotificationSettings.getDifferentValues(
        oldUser?.pushNotificationSettings,
        newUser?.pushNotificationSettings,
      ).length > 0
    ) {
      differentValues.push(
        PushNotificationSettings.getDifferentValues(
          oldUser?.pushNotificationSettings,
          newUser?.pushNotificationSettings,
        ).map(
          (differentValue) =>
            'Push-Benachrichtigungen (' + differentValue + ')',
        ),
      );
    }

    const differentOrganisationalGroups = ArrayUtils.getDifference(
      oldUser?.organisationalGroups,
      newUser?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    const differentUserGroups = ArrayUtils.getDifference(
      oldUser?.userGroups,
      newUser?.userGroups,
    );
    if (
      differentUserGroups[0].length > 0 ||
      differentUserGroups[1].length > 0
    ) {
      differentValues.push('Benutzer-Gruppen');
    }

    return differentValues;
  }

  static TYPE = {
    // deprecated
    SUPPLIER: {
      KEY: 'supplier',
      NAME: 'Lieferant',
    },
    CARRIER: {
      KEY: 'carrier',
      NAME: 'Spediteur',
    },
    RECIPIENT: {
      KEY: 'buyer',
      NAME: 'Abnehmer',
    },
  };
  static ASSET_CREATOR_USER_ID = '8814cf34-fd7a-4a3a-8abd-eea291dfea35';
  static CSV_ALLOWED_COLUMNS = [
    {
      NAME: 'username',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'email',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'password',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'first_name',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'last_name',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'permitted_signature_types',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'company_id',
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID,
    },
    {
      NAME: 'is_active',
      DATA_TYPE: CSVValidator.DATA_TYPE.BOOLEAN,
    },
    {
      NAME: 'position',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'user_type',
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
    },
    {
      NAME: 'org_units',
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID_LIST,
    },
  ];
}
