import { MASTERDATA } from '~/constants/Masterdata';

import MapperService from '~/services/mapper.service';

export const selectUsers = (data) => ({
  rows: data.data?.map(({ companyName, email, id, isActive }) => {
    const row = {
      active: isActive ? MASTERDATA.IS_ACTIVE.YES : MASTERDATA.IS_ACTIVE.NO,
      companyName,
      email,
      id,
    };

    return MapperService.addSearchString(row, ['id', 'email', 'companyName']);
  }),
  users: data.data,
  totalCount: data.totalCount,
});
