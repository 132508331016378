import { memo } from 'react';
import { cn } from '~/utils/tailwind';

type P = {
  title: string;
} & ComponentStyling;

export const PageTitle = memo(({ children, className, style }: P) => (
  <h1 className={cn('text-3xl', className)} style={style}>
    {children}
  </h1>
));
