import React from 'react';

import {
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import CompanyService from '~/services/company.service';
import CostCenterService from '~/services/costCenter.service';
import OrganisationalGroupService from '~/services/organisationalGroup.service';
import PermissionGrantService from '~/services/permissionGrant.service';
import SiteService from '~/services/site.service';
import UserGroupService from '~/services/userGroup.service';
import UserService from '~/services/user.service';
import VehicleService from '~/services/vehicle.service';

import PermissionGrant from '~/models/masterdata/PermissionGrant';
import Permissions from '~/models/masterdata/Permissions';

import ArrayUtils from '~/utils/arrayUtils';
import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import PromiseUtils from '~/utils/promiseUtils';

import { withErrorBoundary } from '~/ui/atoms';
import { MultiSelectUsers } from '~/ui/molecules/SelectServerDriven';

import { Spinner } from '~/components/Spinner';

class UpdatePermissionsWizardDeletePermissions extends React.Component {
  constructor() {
    super();

    this.state = {
      permissionGrants: [],
      permissionGrantsAreLoading: false,
      selectedPermissionGrants: [],
      deleteAllPermissionGrants: false,
      deletePermissionsFromCostCenters: true,
    };

    this.permissionGrantChilds = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.pickedSubjects) !==
        JSON.stringify(prevProps.pickedSubjects) ||
      this.state.deletePermissionsFromCostCenters !==
        prevState.deletePermissionsFromCostCenters
    ) {
      this.initPermissionGrants();
    }
  }

  async initPermissionGrants() {
    this.setState({
      permissionGrantsAreLoading: true,
    });

    const permissionGrants = [];

    for (let index = 0; index < this.props.pickedSubjects.length; index++) {
      const subjectId = this.props.pickedSubjects[index];

      // We need to load the subject to get the permissions that the user has been granted.
      // As we currently only allow permissions to be granted to and deleted from users, we know that subjects can only be users.
      const [user, error] = await promiseHandler(
        UserService.getUser(subjectId, true),
      );

      if (error) {
        Log.error('Failed to load user. id: ' + subjectId);
        continue;
      }

      // Ignore Mitarbeiter as we assume that users don't want to delete Mitarbeiter role in the daily usage.
      const filteredPermissionGrants = user.permissionGrantsOn.filter(
        (permissionGrant) =>
          permissionGrant.getDefaultRoleName() !==
          Permissions.DEFAULT_ROLE.EMPLOYEE.NAME,
      );

      for (const permissionGrant of filteredPermissionGrants) {
        let loadItemCallback = null;

        switch (permissionGrant.entityType) {
          case PermissionGrant.ENTITY_TYPE.USER.KEY: {
            loadItemCallback = UserService.getUserById;
            break;
          }

          case PermissionGrant.ENTITY_TYPE.SITE.KEY: {
            loadItemCallback = SiteService.getSiteById;
            break;
          }

          case PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY: {
            loadItemCallback = CostCenterService.getCostCenterById;
            break;
          }

          case PermissionGrant.ENTITY_TYPE.VEHICLE.KEY: {
            loadItemCallback = VehicleService.getVehicleById;
            break;
          }

          case PermissionGrant.ENTITY_TYPE.COMPANY.KEY: {
            loadItemCallback = CompanyService.getCompanyById;
            break;
          }

          case PermissionGrant.ENTITY_TYPE.ORGANISATIONAL_GROUP.KEY: {
            loadItemCallback =
              OrganisationalGroupService.getOrganisationalGroupById;
            break;
          }

          case PermissionGrant.ENTITY_TYPE.USER_GROUP.KEY: {
            loadItemCallback = UserGroupService.getUserGroupById;
            break;
          }

          default: {
            Log.error(
              null,
              new EnumValueNotFoundException(
                'Invalid entity type: ' + permissionGrant.entityType,
              ),
            );
            continue;
          }
        }

        const [entity, error2] = await promiseHandler(
          loadItemCallback(permissionGrant.entityId),
        );

        if (error2) {
          Log.error('Failed to load entity. id: ' + permissionGrant.entityId);
          continue;
        }

        permissionGrant.userEmail = user.email;
        permissionGrant.entityName = entity?.name ?? ''; // The ? and ?? operators are necessary because it can be that the user doesn't have access to read the specific entity.
        if (
          permissionGrant.entityType === PermissionGrant.ENTITY_TYPE.USER.KEY
        ) {
          permissionGrant.entityName = entity?.email ?? '';
        }

        if (
          permissionGrant.entityType === PermissionGrant.ENTITY_TYPE.VEHICLE.KEY
        ) {
          permissionGrant.entityName = entity?.licensePlate?.name ?? '';
        }

        permissionGrant.entity = entity;

        permissionGrants.push(permissionGrant);
      }
    }

    let sortedPermissionGrants = permissionGrants.sort(
      (a, b) =>
        a.userEmail.localeCompare(b.userEmail) ||
        b.entityType.localeCompare(a.entityType) || // Reverse the order of entity types because we want to access sites before cost centers.
        a.entityName.localeCompare(b.entityName) ||
        a.getDefaultRoleName().localeCompare(b.getDefaultRoleName()),
    );

    for (const permissionGrant of sortedPermissionGrants) {
      let permissionGrantCostCenters = [];

      // If sites and connected cost centers should both be deleted, search for the corresponding cost centers, add them to the permission grant and remove them from the list.
      if (
        this.state.deletePermissionsFromCostCenters &&
        permissionGrant.entityType === PermissionGrant.ENTITY_TYPE.SITE.KEY
      ) {
        // Search for the corresponding cost centers and add them to the permission grant.
        permissionGrantCostCenters = sortedPermissionGrants.filter(
          (permissionGrantCostCenter) =>
            permissionGrant.entity?.costCenters?.includes(
              permissionGrantCostCenter.entityId,
            ) &&
            permissionGrant.subjectId === permissionGrantCostCenter.subjectId &&
            JSON.stringify(permissionGrant.permissions) ===
              JSON.stringify(permissionGrantCostCenter.permissions),
        );

        // Remove the connected cost centers from the list.
        sortedPermissionGrants = sortedPermissionGrants.filter(
          (permissionGrant) =>
            !permissionGrantCostCenters.find(
              (permissionGrantCostCenter) =>
                permissionGrantCostCenter.id === permissionGrant.id,
            ),
        );
      }

      permissionGrant.permissionGrantCostCenters = permissionGrantCostCenters;

      permissionGrant.label = (
        <>
          <span className="bold">{permissionGrant.userEmail}</span>
          <span className="text-grey600">
            {'\u00A0auf\u00A0' +
              permissionGrant.getEntityTypeString() +
              '\u00A0'}
          </span>
          <span className="bold">{permissionGrant.entityName}</span>
          {permissionGrantCostCenters.length > 0 ? (
            <>
              <span className="text-grey600">
                {'\u00A0und\u00A0Kostenstelle(n)\u00A0'}
              </span>
              <span className="bold">
                {permissionGrantCostCenters
                  .map(
                    (permissionGrantCostCenter) =>
                      permissionGrantCostCenter.entityName,
                  )
                  .join(', ')}
              </span>
            </>
          ) : null}
          <span className="text-grey600">{'\u00A0als\u00A0'}</span>
          <span className="bold">{permissionGrant.getDefaultRoleName()}</span>
        </>
      );
    }

    this.setState({
      permissionGrants: sortedPermissionGrants,
      permissionGrantsAreLoading: false,
      // Remove the permission grants from the selected ones that have been implicitly deselected because a user has been deselected.
      selectedPermissionGrants: this.state.selectedPermissionGrants.filter(
        (permissionGrantId) =>
          sortedPermissionGrants.find(
            (permissionGrant) => permissionGrant.id === permissionGrantId,
          ),
      ),
    });
  }

  handleDeletePermissionsRadioChange = (event) => {
    Log.info(
      'Change form value of delete permissions radio button',
      { from: this.props.deletePermissions, to: event.target.value },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change delete permissions radio button in update permissions wizard',
      Log.FEATURE.WIZARD,
    );
    this.props.setDeletePermissions(
      event.target.value === 'delete_permissions_yes',
    );
  };
  handleDeletePermissionsFromCostCentersRadioChange = (event) => {
    Log.info(
      'Change form value of delete permissions from cost centers radio button',
      {
        from: this.state.deletePermissionsFromCostCenters,
        to: event.target.value,
      },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change delete permissions from cost centers radio button in update permissions wizard',
      Log.FEATURE.WIZARD,
    );
    this.setState({
      deletePermissionsFromCostCenters:
        event.target.value === 'cost_centers_yes',
    });
  };
  handleChangePickedSubjects = (event) => {
    const newPickedSubjects = event.map((item) => item.id);

    Log.info(
      'Change form value of subjects',
      { from: this.state.pickedSubjects, to: newPickedSubjects },
      Log.BREADCRUMB.FORM_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change subjects', Log.FEATURE.WIZARD);

    this.props.setPickedSubjects(newPickedSubjects);
  };
  handleChangeCheckbox = (event) => {
    if (event.target.name === 'delete_all') {
      Log.info(
        'Change checkbox value of delete_all',
        {
          from: this.state.deleteAllPermissionGrants,
          to: !this.state.deleteAllPermissionGrants,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Change delete all permissions checkbox in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      this.setState({
        selectedPermissionGrants: this.state.deleteAllPermissionGrants
          ? []
          : this.state.permissionGrants.map(
              (permissionGrant) => permissionGrant.id,
            ),
        deleteAllPermissionGrants: !this.state.deleteAllPermissionGrants,
      });

      return;
    }

    if (this.state.selectedPermissionGrants.includes(event.target.name)) {
      Log.info(
        'Change checkbox value of to be deleted permission',
        {
          from: this.state.deleteAllPermissionGrants,
          to: !this.state.deleteAllPermissionGrants,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Activate to be deleted permission checkbox in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      this.setState({
        selectedPermissionGrants: ArrayUtils.remove(
          [...this.state.selectedPermissionGrants],
          event.target.name,
        ),
        deleteAllPermissionGrants: false,
      });
    } else {
      Log.info(
        'Change checkbox value of to be deleted permission',
        {
          from: this.state.deleteAllPermissionGrants,
          to: !this.state.deleteAllPermissionGrants,
        },
        Log.BREADCRUMB.FORM_CHANGE.KEY,
      );
      Log.productAnalyticsEvent(
        'Deactivate to be deleted permission checkbox in update permissions wizard',
        Log.FEATURE.WIZARD,
      );

      const newSelectedPermissionGrants = [
        ...this.state.selectedPermissionGrants,
        event.target.name,
      ];

      this.setState({
        selectedPermissionGrants: newSelectedPermissionGrants,
        deleteAllPermissionGrants:
          newSelectedPermissionGrants.length ===
          this.state.permissionGrants.length,
      });
    }
  };

  async submit() {
    const promises = [];

    for (const permissionGrantId of this.state.selectedPermissionGrants) {
      const permissionGrant = this.state.permissionGrants.find(
        (permissionGrant) => permissionGrant.id === permissionGrantId,
      );

      const promise = PermissionGrantService.deletePermissionGrant(
        permissionGrant.id,
      );
      promises.push(promise);

      // Delete all permissions from the corresponding cost centers.
      for (const permissionGrantCostCenter of permissionGrant.permissionGrantCostCenters) {
        const promise = PermissionGrantService.deletePermissionGrant(
          permissionGrantCostCenter.id,
        );
        promises.push(promise);
      }
    }

    return PromiseUtils.allResolved(promises);
  }

  getPermissionGrantCheckbox = (permissionGrant) => {
    return (
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.selectedPermissionGrants.includes(
                permissionGrant.id,
              )}
              onChange={this.handleChangeCheckbox}
              name={permissionGrant.id}
            />
          }
          label={permissionGrant.label}
        />
      </Grid>
    );
  };

  render() {
    return (
      <Grid container direction="row" spacing={3} space={4}>
        <Grid item xs={12} lg={12}>
          <h3 className="main-text mt-0">
            Sollen bestehende Berechtigungen gelöscht werden?
          </h3>
          <RadioGroup
            onChange={this.handleDeletePermissionsRadioChange}
            value={
              this.props.deletePermissions
                ? 'delete_permissions_yes'
                : 'delete_permissions_no'
            }
            row
          >
            <FormControlLabel
              value="delete_permissions_yes"
              control={<Radio />}
              label="Ja"
              className="mr-50px"
            />
            <FormControlLabel
              value="delete_permissions_no"
              control={<Radio />}
              label="Nein"
            />
          </RadioGroup>
        </Grid>
        {this.props.deletePermissions ? (
          <>
            <Grid item xs={12} lg={12}>
              <h3 className="mt-20px main-text">
                Sollen die Berechtigungen von den jeweils verbundenen Standorten
                und Kostenstellen gelöscht werden?
              </h3>
              <RadioGroup
                onChange={
                  this.handleDeletePermissionsFromCostCentersRadioChange
                }
                value={
                  this.state.deletePermissionsFromCostCenters
                    ? 'cost_centers_yes'
                    : 'cost_centers_no'
                }
                row
              >
                <FormControlLabel
                  value="cost_centers_yes"
                  control={<Radio />}
                  label="Ja (empfohlen)"
                  className="mr-50px"
                />
                <FormControlLabel
                  value="cost_centers_no"
                  control={<Radio />}
                  label="Nein"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} lg={12}>
              <h3 className="mt-20px main-text">
                Welche Berechtigungen sollen gelöscht werden?
              </h3>
              <MultiSelectUsers
                // Currently, we only allow to change the permissions for users as it makes the logic of the wizard simpler.
                label="Für welche Benutzer sollen Berechtigungen gelöscht werden?"
                value={this.props.pickedSubjects}
                onChange={this.handleChangePickedSubjects}
                fullWidth
              />
              {this.state.permissionGrantsAreLoading ? <Spinner /> : null}
              {!this.state.permissionGrantsAreLoading &&
              this.state.permissionGrants.length > 0 ? (
                <Grid item xs={12} className="mt-20px">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.deleteAllPermissionGrants}
                        onChange={this.handleChangeCheckbox}
                        name="delete_all"
                      />
                    }
                    label="Sollen alle bestehenden Berechtigungen gelöscht werden?"
                  />
                </Grid>
              ) : null}
              {this.state.permissionGrantsAreLoading
                ? null
                : this.state.permissionGrants.map((permissionGrant) =>
                    this.getPermissionGrantCheckbox(permissionGrant),
                  )}
            </Grid>
          </>
        ) : null}
      </Grid>
    );
  }
}

export default withErrorBoundary(
  UpdatePermissionsWizardDeletePermissions,
  'Daten konnten nicht geladen werden.',
);
