import { useEffect } from 'react';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import ms from 'ms';
import snakecaseKeys from 'snakecase-keys';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import axios from '~/utils/api-client';

import Config from '~/Config';

import { queryKeysCostCenter } from './queryKeys';

const defaultQueryParams = {
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  searchString: undefined,
  filterAccRefType: undefined,
  filterActive: undefined,
  filterCompany: undefined,
  orderBy: 'name',
  sort: 'ASC',
};

/**
 * Generates the query options for the cost centers query so they can be shared between the useQuery hook and the prefetching.
 *
 * @param {Object} queryParams - The query parameters for fetching cost centers.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getCostCentersQueryOptions = ({ queryParams, options = {} }) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return {
    queryKey: queryKeysCostCenter.getAll(qp),
    queryFn: () => fetchCostCenters(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all cost centers from the API.
 * @param {Object} queryParams - The query parameters for fetching cost centers.
 * @returns {Promise<Object|null>} The cost center data if successful, null otherwise.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Accounting-Reference/operation/get_accounting_references_accounting_reference_get
 */
export const fetchCostCenters = async (queryParams) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.sort = qp.sort?.toUpperCase();

  for (const key of Object.keys(qp)) {
    if (qp[key] === undefined) {
      delete qp[key];
    }
  }

  return axios
    .get(`${Config.apiUrl}/accounting_reference`, {
      params: snakecaseKeys(qp),
    })
    .then(({ data, status }) =>
      status === 200 ? camelcaseKeys(data, { deep: true }) : null,
    );
};

/**
 * React Query based custom hook for getting the data for all cost centers with given query parameters.
 * Handles pagination and prefetches the next page of cost centers for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching cost centers.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryCostCenters = (queryParams, options) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getCostCentersQueryOptions({
        queryParams: {
          ...queryParams,
          offset: queryParams.offset + queryParams.limit,
        },
        options,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery({
    ...getCostCentersQueryOptions({ queryParams, options }),
    placeholderData: keepPreviousData,
  });
};
