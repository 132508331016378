import { PROMISE_STATUS } from '~/constants/AsyncOperationConsts';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import UserGroupService from '~/services/userGroup.service';
import UserService from '~/services/user.service';

import PermissionGrant, {
  ENTITY_TYPES,
} from '~/models/masterdata/PermissionGrant';

import Log from '~/utils/Log';
import { promiseHandler } from '~/utils/promiseHandler';
import PromiseUtils from '~/utils/promiseUtils';
import UserUtils from '~/utils/userUtils';

import { PermissionGrantTableBase } from './PermissionGrantTableBase';

const getSubjectName = async (subjectType, subjectId) => {
  switch (subjectType) {
    case PermissionGrant.SUBJECT_TYPE.USER.KEY: {
      const [user, error] = await promiseHandler(
        UserService.getUserById(subjectId),
      );

      if (error) {
        throw error;
      }

      return user.email;
    }

    case PermissionGrant.SUBJECT_TYPE.USER_GROUP.KEY: {
      const [userGroup, error7] = await promiseHandler(
        UserGroupService.getUserGroupById(subjectId),
      );

      if (error7) {
        throw error7;
      }

      return userGroup.name;
    }

    default: {
      Log.error(
        null,
        new EnumValueNotFoundException(`Invalid subject type: ${subjectId}`),
      );

      return '...';
    }
  }
};

const initRows = async (permissionGrantsFrom, setState) => {
  const promises = permissionGrantsFrom.map(({ subjectId, subjectType }) =>
    getSubjectName(subjectType, subjectId),
  );

  const [results, error] = await promiseHandler(
    PromiseUtils.allResolved(promises),
  );

  if (error) {
    Log.error('Failed to load subjects.', error);
    Log.productAnalyticsEvent(
      'Failed to load subjects',
      Log.FEATURE.PERMISSIONS,
      Log.TYPE.ERROR,
    );

    return;
  }

  const newRows = [];
  for (const [index, result] of results.entries()) {
    const { id, permissions, subjectId, subjectType } =
      permissionGrantsFrom[index];

    newRows.push({
      defaultRole: permissions.getDefaultRoleName(),
      id,
      permissions: {
        permissions,
        permissionGrantId: id,
      },
      subjectId,
      subjectName:
        result.status === PROMISE_STATUS.FULFILLED ? result.value : '...',
      subjectType: ENTITY_TYPES[subjectType],
    });
  }

  setState((prevState) => ({
    ...prevState,
    rows: newRows,
  }));
};

export const PermissionGrantEntityTable = ({
  defaultEntities,
  defaultEntityType,
  fixedPicker,
  loading,
  permissionGrantsFrom,
  refreshData,
  title,
}) => (
  <PermissionGrantTableBase
    columnVisibilityModel={{ subjectId: UserUtils.isVestigasAccount() }}
    defaultEntities={defaultEntities}
    defaultEntityType={defaultEntityType}
    fixedPicker={fixedPicker}
    initRows={initRows}
    loading={loading}
    permissionGrants={permissionGrantsFrom}
    refreshData={refreshData}
    title={title}
    type={PermissionGrant.TYPE.ENTITY}
  />
);

PermissionGrantEntityTable.displayName = 'PermissionGrantEntityTable';
