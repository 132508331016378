import * as React from 'react';

import { Close as CloseIcon } from '@mui/icons-material';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

const BasicModal = ({
  children,
  className,
  closeModal,
  fullWidth,
  opacity,
  open,
  title,
}) => (
  <Dialog
    open={Boolean(open)}
    onClose={closeModal}
    className={className}
    fullWidth={fullWidth}
    maxWidth="lg"
    scroll="paper"
    slotProps={{
      backdrop: {
        style: {
          backgroundColor: 'black',
          opacity: opacity ?? 0.3,
        },
      },
    }}
    PaperProps={{
      style: {
        overflow: 'visible',
      },
    }}
    aria-describedby="modal-modal-description"
    aria-labelledby="modal-modal-title"
  >
    <div className="flex items-center justify-between gap-4">
      <div className="flex-1 flex-wrap">
        <DialogTitle className="dialog-title">{title}</DialogTitle>
      </div>
      <IconButton onClick={closeModal} size="large" className="flex-0 mr-2">
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent className="pt-0">
      <Box>{children}</Box>
    </DialogContent>
  </Dialog>
);

export default BasicModal;
