import ms from 'ms';
import { useEffect } from 'react';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import { type UUID } from '~/types/common';

import Log from '~/utils/Log';

import { queryKeysUserGroup } from './queryKeys';

export type UserGroupListItem = {
  companyAccountId: UUID;
  createdById: UUID;
  createdOn: string;
  id: UUID;
  modifiedById: UUID;
  modifiedOn: string;
  name: string;
  syncKey: string | undefined;
};

export type UserGroupsResponse = {
  count: number;
  data: UserGroupListItem[];
};

export type UserGroupsQueryParams = {
  limit: number;
  offset: number;
  searchString?: string;
  filterCompanyAccount?: string;
  orderBy?: string;
  sort?: 'ASC' | 'DESC';
};

/**
 * Generates the query options for the user groups query.
 *
 * @param {Object} queryParams - The query parameters for fetching user groups.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getUserGroupsQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<UserGroupsQueryParams>;
  options?: Parameters<typeof useQuery>[0];
}) => {
  const qp = {
    limit: PAGINATION_PAGE_SIZE_DEFAULT,
    offset: 0,
    ...queryParams,
  };

  return {
    queryKey: queryKeysUserGroup.getAll(qp),
    queryFn: async () => fetchUserGroups(qp),
    staleTime: ms('60s'), // prevent hitting the rate limit of 30 requests/s
    ...options,
  };
};

/**
 * Fetches all user groups from the API.
 * @param {Object} queryParams - The query parameters for fetching user groups.
 * @returns {Promise<UserGroupsResponse|null>} The user group data if successful, null otherwise.
 */
export const fetchUserGroups = async (
  queryParams: Partial<UserGroupsQueryParams>,
): Promise<UserGroupsResponse> => {
  const qp = {
    limit: PAGINATION_PAGE_SIZE_DEFAULT,
    offset: 0,
    ...queryParams,
  };

  qp.sort = qp.sort?.toUpperCase() as 'ASC' | 'DESC';

  for (const key of Object.keys(qp)) {
    if (qp[key] === undefined) {
      delete qp[key];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.USER_GROUP.GET_ALL, {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<UserGroupsResponse>();

    return response ?? { count: 0, data: [] };
  } catch (error) {
    Log.error('Error fetching user groups', error);
    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all user groups with given query parameters.
 * Handles pagination and prefetches the next page of user groups for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching user groups.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryUserGroups = (
  queryParams: Partial<UserGroupsQueryParams>,
  options: Parameters<typeof useQuery>[0],
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getUserGroupsQueryOptions({
        queryParams: {
          ...queryParams,
          offset:
            (queryParams.offset ?? 0) +
            (queryParams.limit ?? PAGINATION_PAGE_SIZE_DEFAULT),
        },
        options,
      }),
    );
  }, [JSON.stringify(queryParams), JSON.stringify(options), queryClient]);

  return useQuery({
    ...getUserGroupsQueryOptions({ queryParams, options }),
    placeholderData: keepPreviousData,
  });
};
